import React from 'react'

export default function SocialMedia() {
    const social_list = [
        {
            "id":"12d642c3-4ed9-4f90-99a1-20b5a970d991",
            "name": "LinkedIn",
            "href": "https://www.linkedin.com/in/hhphu/",
            "icon": "ri-linkedin-box-line"
        },
        {
            "id":"f3f93d7d-f4cb-43c9-9c86-e17fc72ebf93",
            "name": "Github",
            "href": "https://github.com/hhphu",
            "icon": "ri-github-line"
        },
        {
            "id":"09bcec97-2bba-4a84-87e1-0bc8a7ac2c26",
            "name": "Twitter",
            "href": "https://twitter.com/hackingphu",
            "icon": "ri-twitter-line"
        },
                {
            "id":"ab4ae7b5-b5d5-4da8-9a35-08f1bdeb96ff",
            "name": "YouTube",
            "href": "https://www.youtube.com/@hackingphu",
            "icon": "ri-youtube-line"
        },
                {
            "id":"639034ef-41d1-48cb-97f9-799e6e6f885b",
            "name": "Instagram",
            "href": "https://www.instagram.com/hackingphu/",
            "icon": "ri-instagram-line"
        },
        {
            "id":"6e9d350b-8509-42d9-86c3-146dfcd6f4d6",
            "name": "Website",
            "href": "https://hqphu.com",
            "icon": "ri-global-line"
        }

    ]

    const displaySocialMedia = social_list.map((social) => {
        return (
            <a
             id={social.id}
             key={social.name}
             href={social.href}
             target="_blank"
             rel="noreferrer"
             className="profile__social-link"
            >
                <i className={social.icon}></i>
            </a>
        )
    })
  return (
    <ul className="profile__social">
        {displaySocialMedia}
    </ul>
  )
}