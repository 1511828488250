import {images} from '../constant'

const ProjectsList = [
    {
        "uid":"cc95526f-fa88-4bce-91f6-7571aeaf3b0a",
        "title":"Vulnerability Assessment",
        "subtitle":" Security",
        "url":"https://github.com/hhphu/InfoSec/tree/main/Projects/vulnerability-assessment",
        "image": images.vulnerability_assessment
    },
    {
        "uid":"23dca6ac-164f-44d6-a389-121b110de93d",
        "title":"Red Teaming Operation",
        "subtitle":"Cybersecurity - Red Teaming",
        "url":"https://www.hqphu.com/posts/36M9BpxDluSAnvX47ayNjX",
        "image": images.redteam
    } ,
    {
        "uid":"a816d2d0-b024-4e4e-b9d9-bba6977e6544",
        "title":"AI Spam Detector",
        "subtitle":"AI, Machine Learning",
        "url":"https://github.com/hhphu/InfoSec/tree/main/Projects/ai-detect-spams",
        "image": images.ai
    },
    {
        "uid":"0a9a5146-115f-494b-8b37-88a63a93db59",
        "title":"Secure Azure SIEM Infrastructure",
        "subtitle":" Security",
        "url":"https://www.hqphu.com/posts/6kAWd9Mm6i9jXrkiE90tct",
        "image": images.siem
    },
    {
        "uid": "400b896b-69a3-4470-8ad5-586133200a87",
        "title":"Cybersecurity - Red Teaming",
        "subtitle": "Web",
        "url":"https://www.hqphu.com/posts/G8fz6BdzfXVktRLFVnPhW",
        "image": images.osint
    },
    {
        "uid":"4a0d5924-e9c6-4e7a-8f79-b36014ff441d",
        "title":"Security Architecture",
        "subtitle":" Security",
        "url":"https://github.com/hhphu/InfoSec/tree/main/Projects/security-architect",
        "image": images.security_architecture
    }


    
]

export default ProjectsList;
