import profile2Removebg from "../pictures/profile2-removebg.png"
import osint from "../pictures/osint.png"
import redteam from "../pictures/redteam.jpg"
import siem from "../pictures/siem.jpeg"
import ai from "../pictures/ai-spam-detector.png"
import security_architecture from "../pictures/security-architecture.jpg"
import vulnerability_assessment from "../pictures/vulnerability-assessment.jpeg"

const images = {
    profile2Removebg,
    osint,
    redteam,
    siem,
    ai,
    security_architecture,
    vulnerability_assessment
}

export default images;