import React, {useState} from "react"
import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import AppContext from './components/AppContext'
import SkillsList from "./assets/content/SkillsList";



function App() {
  const [activeIndex, setActiveIndex]=useState(0)
  
  const userSettings = {activeIndex, setActiveIndex, SkillsList}
  return (
    <AppContext.Provider value={userSettings}>
      {/* <DisplayResume/> */}
      <Header/>
      <Main/>
      <Footer/>

    </AppContext.Provider>
  );
}

export default App;
