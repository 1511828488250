const SkillsList = [    
    {
        "uid":"0a9a5146-115f-494b-8b37-88a63a93db59",
        "title":"Security Engineer",
        "skills": ["Network Security","Web Security","Cloud Security","Risk Assessment", "Bash", "Powershell", "Active Directory", "IAM"]
    },
    {
        "uid": "400b896b-69a3-4470-8ad5-586133200a87",
        "title":"Others",
        "skills": ["JavaScript","React", "Python", "Automation", "Docker", "Kubernetes","Cloud", "Blackbox","Whitebox","CI/CD"]
  
    }
]

export default SkillsList;