import React,{useContext} from 'react'
import SkillsArea from './SkillsArea'
import AppContext from '../AppContext'

export default function Skills() {
    const myContext = useContext(AppContext)
    const primarySkills = myContext.SkillsList[0]
    const secondarySkills = myContext.SkillsList[1]
    
    return (
        <div className="skills__content grid filters__active" data-content id="skills">
            <SkillsArea skills={primarySkills}/>
            <SkillsArea skills={secondarySkills}/>
        </div>
    )
}
