import React from 'react'

function SkillsGroup({list}) {
  const first = list.slice(0, Math.ceil(list.length/2))
  const second = list.slice(Math.ceil(list.length/2), list.length)

  function displayColumn(sublist){
    const temp_list = sublist.map((s,i) => {
      return (
        <div className="skills__data" key={i}>
          <i className="ri-checkbox-circle-line"></i>
          <div>
            <h3 className="skills__name">{s}</h3>
          </div>
      </div>
      )
    })
    return(
      <div className="skills__group">
        {temp_list}
      </div>
    )
  }

  return (
    <div className="skills__box">
      {displayColumn(first)}
      {displayColumn(second)}
    </div>
  )
}

export default SkillsGroup